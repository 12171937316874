<template>
  <v-btn
    text
    height="100%"
    class="rounded-0 menu-btn px-7"
    :to="route"
    exact
    active-class="menu-btn-active"
    ><v-col cols="12">
      <v-row width="100%" style="place-content: center; margin-bottom: 12px">
        <v-icon large>{{ icon }}</v-icon>
      </v-row>
      <v-row width="100%" style="place-content: center">
        <span class="menu-btn-caption"> {{ title }} </span>
      </v-row>
    </v-col>
  </v-btn>
</template>

<script>
export default {
  name: "MenuItem",
  props: ["title", "icon", "route"],
};
</script>
