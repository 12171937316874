<template>
    <v-footer
      padless
    >
      <v-card
        flat
        tile
        width="100%"
        class="fter lighten-1 text-center"
      >
        <v-container>
          <v-row>
            <v-col>
              <v-row>
                <v-col class="fter-title">
                  ZADZWOŃ LUB NAPISZ
                </v-col>
              </v-row>
              <v-row>
                <v-col class="fter-item">
                   +48 697 019 040
                </v-col>
              </v-row>
              <v-row>
                <v-col class="fter-item">
                   biuro@perelkawroclaw.pl
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="fter-title">
                  ODWIEDŹ NAS
                </v-col>
              </v-row>
              <v-row>
                <v-col class="fter-item">
                  ul.A.Vespucciego 6a<br>
                  51-504 Wrocław<br>
                  osiedle Olimpia Port<br>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-row>
                <v-col class="fter-title">
                  POZNAJ NAS
                </v-col>
              </v-row>
              <v-row>
                <v-col class="fter-item" @click.stop="$router.push({name: 'ONas'})">
                   O nas
                </v-col>
              </v-row>
              <v-row>
                <v-col class="fter-item text-center">
                  <v-img
                    class="shrink mx-auto"
                    contain
                    src="../assets/fb_icon.png"
                    max-height="30"
                    max-width="30"
                    width="30"
                    @click="open()"
                  />
                </v-col>
              </v-row>
            </v-col>
            
          </v-row>
        </v-container>
      </v-card>
    </v-footer>
</template>

<script>
export default {
  methods: {
    open() {
      window.open('https://www.facebook.com/PerelkaPrzedszkoleMontessoriWroclaw', '_blank').focus();
    }
  } 
}

</script>