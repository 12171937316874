<template>
  <v-app>
    <app-menu></app-menu>
    <mini-menu></mini-menu>
    <v-container class="mb-16">
      <v-main>
        <router-view />
      </v-main>
    </v-container>
    <app-footer></app-footer>
  </v-app>
</template>

<script>
import AppMenu from "./components/Menu.vue";
import MiniMenu from "./components/MiniMenu.vue";
import AppFooter from "./components/Footer.vue";

export default {
  components: { AppMenu, AppFooter, MiniMenu },
  name: "App"
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import '~vuetify/src/styles/settings/_variables';
.mini-menu {
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white !important;
  @media screen and (min-width: 450px) {
    display: none;
  }
  .v-toolbar__content {
    width: 100%;
    padding: 0;
  }
  &::before {
  opacity: 0 !important;
  }
}
.app-toolbar {
  // margin-top: 56px;
  
  @media screen and (min-width: 450px) {
    margin-top: revert;
  }
  .v-toolbar__content {
    padding-top: 0 !important;
  }
  .app-logo-wrapper {
    width: 100%;
    @media screen and (min-width: 450px) {
      width: revert;
    }
    .v-image{
      margin: auto !important;
      @media screen and (min-width: 450px) {
        margin: revert;
      }
    }
  }
  .burger {
    max-width: 60px !important;
    width: 13vw !important;
    max-height: 60px !important;
    height: 13vw !important;
    background-color: transparent;
    color: #56509f !important;
    display: none !important;
    &:hover {
      background-color: #fe6500;
      color: white !important;
    }
    i {
      font-size: 8vw !important;
      @media screen and (min-width: 450px) {
          font-size: 40px !important;
      }
    }
    @media screen and (min-width: 450px) {
      display: flex !important;
    }
    @media #{map-get($display-breakpoints, 'lg-and-up')} {
      display: none !important;
    }
  }
}
.mini-toolbar {
  display: none;
  @media screen and (min-width: 450px) {
    display: revert !important;
  }
  .burger {
    background-color: transparent;
    color: #56509f !important;
    &:hover {
      background-color: #fe6500;
      color: white !important;
    }
    @media screen and (min-width: 450px) {
      display: flex !important;
    }
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: none !important;
    }
  }
}
.menu-btn-caption {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  font-size: 16px !important;
  letter-spacing: normal;
  text-indent: 0;
}

.menu-btn-title {
  color: #56509f !important;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  text-transform: none;
  font-size: 18px !important;
  letter-spacing: normal;
  text-indent: 0;
  &.v-icon{
    font-size: 24px !important;
  }
}
.menu-btn {
  background-color: transparent;
  color: #56509f !important;
  &.menu-btn-active {
    background-color: #fe6500;
    color: white !important;
    .menu-btn-title {
      color: white !important;
    }
  }
  &:hover {
    @extend .menu-btn-active
  }
  &::before {
  opacity: 0 !important;
}
}
.logo-pointer {
  cursor: pointer;
}
h1 {
  color: #5185d8;
  font-size: 55px;
  font-weight: 300;
  font-family: 'Lato';
}
h2 {
  color: #56509F;
  font-size: 30px;
  font-weight: 400;
  font-family: 'Lato';
}
h3 {
  color: #56509F;
  font-size: 24px;
  font-weight: 700;
  font-family: 'Lato';
}
p, ul, ol{
   font-family:  'Open Sans' !important;
   color: #707070 !important;
}
ul, ol {
  margin-top: 5px;
}

.img-caption {
   font-style: italic;
   font-family:  'Open Sans';
   color: #707070;
}

ul li{
  margin-bottom: 8px;
  &::marker{
    color: #f6b212; /* Change the color */
  }
}
.fter {
  padding-bottom: 30px;
  padding-top: 30px;
  background-color: #5185d8 !important;
  .fter-title {
    color: #F6B212;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 18px;
  }
  .fter-item {
    color: white;
    font-family: 'Open Sans';
    font-weight: bold;
    font-size: 14px;

  }
}
.main-content {
  min-height: 700px;
}
</style>