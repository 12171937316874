<template>
  <v-btn
    text
    class="rounded-0 px-7 menu-btn"
    height="100%"
    :to="route"
    exact
    active-class="menu-btn-active"
  >
  <span class="menu-btn-caption">{{ title }}</span>
  </v-btn>
</template>

<script>
export default {
  name: "SmallMenuItem",
  props: ["title", "route"],
};
</script>
