<template>
  <div>
    <v-toolbar light prominent flat class="app-toolbar">
      <v-container class="pa-0 fill-height text-center">
          <div class="d-flex align-center app-logo-wrapper">
            <v-img
              alt="Vuetify Logo"
              class="shrink mr-2 logo-pointer"
              contain
              src="../assets/logo-1.jpg"
              transition="scale-transition"
              max-height="120"
              max-width="310"
              width="95vw"
              @click="$router.push({ name: 'Home' }).catch(() => {})"
            />
          </div>
          <v-spacer></v-spacer>
          <menu-item
            v-for="(r, i) in $router.options.routes.filter(x => !x.meta.skipMain)"
            class="d-none d-lg-flex"
            :title="r.meta.prettyName"
            :icon="r.meta.icon"
            :key="i"
            :route="{name: r.name}"
          ></menu-item>
          <v-app-bar-nav-icon
            @click="drawer = true"
            class="d-flex d-lg-none burger"
          ></v-app-bar-nav-icon>
      </v-container>
    </v-toolbar>
    <v-app-bar app light dense inverted-scroll color="white" class="mini-toolbar">
      <v-container class="pa-0 fill-height">
        <div style="width: 310px" class="d-none d-lg-flex"></div>
        <v-spacer ></v-spacer>
        <template v-for="(r, i) in $router.options.routes">
        <small-menu-item
          class="d-none d-md-flex"
          :title="r.meta.prettyName"
          :icon="r.meta.icon"
          :key="i"
          :route="{name: r.name}"
        ></small-menu-item>
        <v-spacer :key="i+'s'" class="d-none d-md-flex d-lg-none"></v-spacer>
        </template>
        <v-app-bar-nav-icon
          @click="drawer = true"
          class="burger"
        ></v-app-bar-nav-icon>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" fixed temporary right>
      <v-list nav class="px-0">
        <v-list-item-group>
          <v-list-item
            v-for="(r, index) in $router.options.routes"
            :key="index"
            :to="{name: r.name}"
            active-class="menu-btn-active"
            class="menu-btn rounded-0"
            exact
          >
          <v-list-item-icon>
            <v-icon  class="menu-btn-title" v-text="r.meta.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-btn-title">{{
              r.meta.prettyName
            }}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MenuItem from "./MenuItem.vue";
import SmallMenuItem from "./SmallMenuItem.vue";

export default {
  components: { MenuItem, SmallMenuItem },
  name: "Menu",

  data() {
    return {
      drawer: false,
    };
  },
};
</script>
