import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'
 

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API,
  },
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
