import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'

Vue.use(VueRouter)

const baseTitle = 'Perełka Montessori'

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: baseTitle,
      prettyName: "Home",
      icon: "mdi-home-outline",
      skipMain: true
    }
  },
  {
    path: '/misja',
    name: 'Misja',
    meta: {
      title: `${baseTitle} • Misja`,
      prettyName: "Misja",
      icon: "mdi-heart-outline"
    }
  },
  {
    path: '/montessori',
    name: 'Montessori',
    meta: {
      title: `${baseTitle} • Montessori`,
      prettyName: "Montessori",
      icon: "mdi-school-outline"
    }
  },
  {
    path: '/o-nas',
    name: 'ONas',
    meta: {
      title: `${baseTitle} • O Nas`,
      prettyName: "O Nas",
      icon: "mdi-magnify"
    }
  },
  {
    path: '/rekrutacja',
    name: 'Rekrutacja',
    meta: {
      title: `${baseTitle} • Rekrutacja`,
      prettyName: "Rekrutacja",
      icon: "mdi-briefcase-variant-outline"
    }
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    meta: {
      title: `${baseTitle} • Kontakt`,
      prettyName: "Kontakt",
      icon: "mdi-phone-outline"
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return goTo(0)
  },
  routes: routes.map(x => ({
    path: x.path,
    name: x.name,
    meta: x.meta,
    component: () => import(`../views/${x.name}.vue`)
  }))
})

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = (to.meta && to.meta.title) || baseTitle;

  });
});


export default router
