<template>
    <v-menu tile>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
            class="rounded-0 mini-menu"
            v-bind="attrs"
            v-on="on"
          >
          <v-list-item-icon>
            <v-icon  class="menu-btn-title">mdi-menu</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-btn-title">{{
              $route.meta.prettyName
            }}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
      </template>
      <v-list nav class="px-0">
        <v-list-item-group>
          <v-list-item
            v-for="(r, index) in $router.options.routes"
            :key="index"
            :to="{name: r.name}"
            active-class="menu-btn-active"
            class="menu-btn rounded-0"
            exact
          >
          <v-list-item-icon>
            <v-icon  class="menu-btn-title" v-text="r.meta.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="menu-btn-title">{{
              r.meta.prettyName
            }}</v-list-item-title>
          </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
</template>
<script>
export default {
  name: "MiniMenu",
}
</script>